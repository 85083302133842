<template>
  <section class="page_cell">
    <el-card>
      <m-search
        :searchItemList="searchItemList"
        @on-search-submit="onSearchSubmit"
        @search="onSearchSubmit"
      ></m-search>
      <div class="flex-center radio-cell" style="justify-content: flex-start">
        <el-radio-group v-model="search.status"  @input="getList(1)">
          <el-radio-button label>全部({{count}})</el-radio-button>
          <el-radio-button :label="1">上架({{upCount}})</el-radio-button>
          <el-radio-button :label="0">下架({{downCount}})</el-radio-button>
          <el-radio-button :label="3">Banner展示({{ bannerCount }})</el-radio-button>
        </el-radio-group>
        <el-button
          class="mlr-sm"
          type="primary"
          style="margin-top: 10px; margin-bottom: 10px; margin-left: 10px"
          @click="$router.push('./edit')"
          >新增资讯</el-button
        >
      </div>
      <el-table
        :data="tableList"
        stripe
        border
        :header-cell-style="{
          'font-size': '14px',
          color: '#909399',
          'font-weight': '400',
          background: '#F7F8FA',
        }"
        style="width: 100%"
        v-loading="loading"
      >
        <el-table-column align="center" label="时间" width="160">
          <template slot-scope="scope">
            <div class="flex_center">
              {{ scope.row.publishTime }}
<!--              {{ scope.row.publishTime === '1970-1-1' ? scope.row.createdAt.slice(0, scope.row.createdAt.length - 9) : scope.row.publishTime }}-->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="标题" >
          <template slot-scope="scope">
            <div class="flex_center">
              <div>{{ scope.row.title || "-" }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="作者" width="196">
          <template slot-scope="scope">
            <div class="flex_center">
              <div>{{ scope.row.userName || "-" }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column sortable prop="seq" align="center" label="排序" width="140">
          <template slot-scope="scope">
            <div class="flex_center">
              {{ scope.row.seq || "-" }}
            </div>
            <el-popover placement="bottom" width="311" trigger="manual" v-model="scope.row.visible">
              <el-row class="y-center ptb-sm">
                <el-col :sm="24" :lg="8">排序</el-col>
                <div style="display: flex; align-items: center; width: 90%">
                  <el-col :sm="24" :lg="16">
                    <el-input-number v-model="sort"></el-input-number>
                  </el-col>
                </div>
              </el-row>
              <el-button @click="updateAdSort(scope.row)" size="small" type="success" style="margin-left:100px" >修改</el-button>
              <el-button type="text" slot="reference"  @click="getSort(scope.row)" >修改排序</el-button>
            </el-popover>

          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" width="140">
          <template slot-scope="scope">
            <div class="flex_center">
              <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="changeStatus($event, scope.row)"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="Banner展示" width="140">
          <template slot-scope="scope">
            <div class="flex_center">
              <el-switch
                v-model="scope.row.bannerShowFlag"
                :active-value="true"
                :inactive-value="false"
                @change="changeStatus2($event, scope.row)"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" width="125">
          <template slot-scope="scope">
            <div>
              <el-button type="text" @click="prevShowEditModal(scope.row)">编辑</el-button>
              <el-button
                type="text"
                @click="deleteItem(scope.row)"
                style="color: #f0232d"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="阅读数" width="140">
          <template slot-scope="scope">
            <div class="flex_center">
              {{ scope.row.visitCount || "-" }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="tableList.length > 0" class="flex_end p-sm">
        <el-pagination
          @current-change="getList"
          @size-change="changePageGetList"
          :current-page.sync="search.page"
          :page-size="search.pageSize"
          layout="total,sizes, prev, pager, next, jumper"
          :total="search.status == '1' ?  upCount : ( search.status == '0' ? downCount : (search.status == '3' ? bannerCount : count))">
        </el-pagination>
      </div>
    </el-card>
    <!-- 详情抽屉 -->
    <el-drawer
      :visible.sync="drawer"
      :append-to-body="true"
      v-if="drawer"
      :with-header="false"
      size="50%">
      <list-detail/>
    </el-drawer>
  </section>
</template>


<script>
import Info from '@/axios/api/info.js'

export default {
  name: 'info',
  data() {
    return {
      search: {
        type: "",
        status: '',
        title: "",
        page: 1,
        pageSize: 10,
        start: '',
        end: ''
      },
      visible: false,
      sort: 1,
      tableList: [],
      loading: false,
      drawer: false, // 抽屉是否显示
      count: 0,
      bannerCount: 0,
      downCount: 0,
      upCount: 0,
      classifyList: []
    }
  },
  computed: {
    searchItemList() {
      return [
        {
          label: "标题",
          type: "input",
          param: "title",
        },
        {
          label: "日期",
          type: "datetimerange",
          valueFormat: "yyyy-MM-dd",
          param: "dateInterval",
          defaultSelect: "", // 下拉框默认选中项
        },
      ];
    },
  },
  created() {
    this.getList()
  },
  methods: {
    onSearchSubmit(item) {
      console.log("item", item);
      if (item.dateInterval) {
        this.search.start = item.dateInterval[0];
        this.search.end = item.dateInterval[1];
      } else {
        this.search.start = "";
        this.search.end = "";
      }
      this.search.title = item.title
      console.log("this.search",this.search);
      this.getList(1);
    },
    async getList(i) {
      if (i) {
        this.search.page = i;
      }
      this.loading = true
      let res = await Info.getInfoList(this.search)
      this.loading = false
      if (res.status == 1) {
        this.tableList = res.data || []
        this.count = res.extra.total
        this.bannerCount = res.extra.bannerCount
        this.downCount = res.extra.countOFF
        this.upCount = res.extra.countON
      }
    },
    // 页大小改变请求列表数据
    changePageGetList(size){
      this.search.pageSize = size
      this.getList(1)
    },
    // 点击筛查、重置按钮
    // onSearchSubmit(item) {
    //   console.log(item);
    //   this.search = {
    //     ...this.search,
    //     ...item,
    //   };
    //   this.getList(1);
    // },
     // 打开分类抽屉
     openClassfyDrawer() {
      this.drawer = true
    },
    // 打开详情抽屉
    openDetailDrawer(row) {
      this.$router.push({ path: "./edit", query: { id: row.id } })
    },
    // 打开编辑
    prevShowEditModal(row) {
      this.$router.push({path: './edit', query: {
        id: row.id
      }})
    },
    // 删除列表项
    deleteItem(row) {
      let that = this
      this.$Modal.confirm({
        title: '提示',
        content: '是否删除，请确认',
        onOk: async () => {
          let res = await Info.delInfo({id: row.id})
          if (res.status == 1) {
            that.getList()
          }
        }
      });
    },
    getSort(item){
      item.visible = true
      this.sort = item.seq
    },
    // 修改排序
    async updateAdSort(item) {
      let res = await Info.editSeq({id: item.id, seq: this.sort})
      this.$message.success('修改成功')
      item.visible = false
      this.getList();
    },
    // 商品上架或下架
    changeStatus(e, row) {
      let oldStatus = row.status == 0 ? 1 : 0
      console.log(oldStatus);
      let that = this;
      row.status = oldStatus
      that.$Modal.confirm({
        title: "提示",
        content: `是否${oldStatus == 0 ? '上' : '下'}架，请确认`,
        onOk: () => {
          Info.editStatus({id: row.id, status: oldStatus}).then((res) => {
            console.log(res);
            if (res.status == 1) {
              that.getList();
              that.$message.success("修改成功！");
            }
          });
        },
      });
    },
    //是否banner展示
    changeStatus2(e, row) {
      console.log(row.bannerShowFlag);
      let oldStatus = !row.bannerShowFlag
      let that = this;
      row.bannerShowFlag = oldStatus
      that.$Modal.confirm({
        title: "提示",
        content: `是否${oldStatus == 0 ? '在' : '取消'}Banner展示，请确认`,
        onOk: () => {
          Info.editBannerShow({id: row.id, bannerShowFlag: oldStatus}).then((res) => {
            console.log(res);
            if (res.status == 1) {
              that.getList();
              that.$message.success("修改成功！");
            }
          });
        },
      });
    },
    getRealValue(obj, property) {
      let value = obj;
      let propertyList = property.split(".");
      propertyList.forEach((name) => {
        if (value) {
          value = value[name];
        }
      });
      if (typeof value === "boolean") {
        return value ? "是" : "否";
      } else {
        return value;
      }
    },
  }
}
</script>
<style lang="less" scoped>
.flex_end {
 display: flex;
 justify-content: flex-end;
}
</style>
<style lang="less">
.page_cell {
  background: #FFFFFF;
  border-radius: 2px 2px 2px 2px;
}
.radio-cell .el-radio-button__orig-radio:checked+.el-radio-button__inner{
  background: #E6F1FC;
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #A3D0FD;
  color: #1989FA;
}

</style>
